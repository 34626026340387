import { LinkFooterItem } from "./Layout";
import org_log from "../../assets/images/college_log.svg";
import left_corner from "../../assets/images/left_corner.png";
import right_corder from "../../assets/images/right_corder.png";

import { useLocation } from "react-router-dom";
export const Footer = () => {
  let location = useLocation();
  return (
    <footer
      className={`py-[40px] space-y-4 footer_wrap px-3 md:px-0 mt-4xl font-opensans xl:mt-[40px] mt-5 max-md:pb-5 border-[#3FC6AD] border-x-0 border-b-0 border-2 `}
    >
      <div className="flex justify-center space-x-1">
        <div className="">
          <img src={org_log} alt="" className="md:w-[64px] xl:w-[64px]" />
        </div>
        <div className="flex items-end px-2">
          <h6 className="text-[#2B574F] font-extrabold xl:text-xl font-opensans">
            MyCollegeCosts
          </h6>
        </div>
      </div>
      <div className="flex justify-center">
        <div className="text-[#3FC6AD] flex flex-row flex-wrap justify-center xl:justify-end md:space-x-12 max-md:space-x-3 md:px-5">
          <LinkFooterItem link="/" title="Home" selected={location.pathname} />
          <LinkFooterItem
            link="/students_parents_guardians"
            title="Students/Parents"
            selected={location.pathname}
          />
          <LinkFooterItem
            link="/college_professionals"
            title="College Professionals"
            selected={location.pathname}
          />
          <LinkFooterItem
            link="/foundations"
            title="Foundations"
            selected={location.pathname}
          />
          <LinkFooterItem
            link="/about_us"
            title="About Us"
            selected={location.pathname}
          />
          {/* <LinkFooterItem
            link="/team"
            title="Team"
            selected={location.pathname}
          /> */}
          <LinkFooterItem
            link="/privacypolicy"
            title="Privacy"
            selected={location.pathname}
          />
          <LinkFooterItem
            link="/contact"
            title="Contact Form"
            selected={location.pathname}
          />
        </div>
      </div>
      <div className="flex justify-center w-full mt-4">
        <p className="text-[#2B574F] w-[90vw] xl:w-[1200px] text-center md:text-sm xl:text-base max-md:text-sm">
          Unauthorized use or reproduction of any content or materials is
          strictly prohibited.
        </p>
      </div>
      <div className="flex justify-center w-full mt-8">
        <h3 className="md:w-2/3 text-center text-[#2B574F]">
          <b>
            <a
              href="https://www.arsome.com"
              className="font-normal underline"
              target={"_blank"}
            >
              Powered by ARSOME Technology
            </a>
          </b>
        </h3>
      </div>
    </footer>
  );
};
