import { useState } from "react";
type IProp = {
  cost: number;
  title: string;
  header: string;
  includes: string[];
  selected: boolean;
  onClick: () => void;
};
const SubType = ({
  cost,
  title,
  header,
  includes,
  selected,
  onClick,
}: IProp) => {
  return (
    <div
      className={`${
        selected ? "bg-[#3FC6AD] text-white " : "bg-white text-[#2B574F]"
      } rounded-lg w-full xl:w-[254px] md:p-6 p-5 grid gap-y-4 font-opensans`}
    >
      <div className="space-y-3 md:flex md:justify-center md:space-x-6 xl:space-x-0 xl:flex-col xl:space-y-3 md:space-y-0 ">
        <div className="flex space-x-1">
          <h6 className="text-3xl font-bold">${cost}/</h6>
          <div className="flex items-end">
            <span>one-time</span>
          </div>
        </div>
        <div className="flex md:items-end md:space-x-5 max-md:flex-col xl:flex-col max-md:justify-start xl:hidden xl:space-x-0">
          <h6
            className={`text-2xl ${selected ? "font-bold" : "font-semibold"}`}
          >
            {title}
          </h6>
          <p>one-time payment</p>
        </div>
        <div className="max-xl:hidden ">
          <h6
            className={`text-2xl ${selected ? "font-bold" : "font-semibold"}`}
          >
            {title}
          </h6>
          <p>one-time payment</p>
        </div>
      </div>

      <ul>
        <h6 className="font-bold mb-2">includes</h6>
        {includes.map((it, idx) => (
          <li key={`${it}-${idx}`} className="flex mb-2 space-x-1">
            <div>
              {selected ? (
                <svg
                  width="20"
                  height="20"
                  viewBox="0 0 20 20"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M10 20C4.47967 19.994 0.00606237 15.5204 0 10V9.80002C0.109931 4.30455 4.63459 -0.0720257 10.1307 0.000898217C15.6268 0.0738221 20.0337 4.5689 19.9978 10.0654C19.9619 15.5618 15.4966 19.9989 10 20ZM5.41 9.59002L4 11L8 15L16 7.00002L14.59 5.58002L8 12.17L5.41 9.59002Z"
                    fill="white"
                  />
                </svg>
              ) : (
                <svg
                  width="21"
                  height="20"
                  viewBox="0 0 21 20"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M10.5 20C4.97967 19.994 0.506062 15.5204 0.5 10V9.80002C0.609931 4.30455 5.13459 -0.0720257 10.6307 0.000898217C16.1268 0.0738221 20.5337 4.5689 20.4978 10.0654C20.4619 15.5618 15.9966 19.9989 10.5 20ZM5.91 9.59002L4.5 11L8.5 15L16.5 7.00002L15.09 5.58002L8.5 12.17L5.91 9.59002Z"
                    fill="#3FC6AD"
                  />
                </svg>
              )}
            </div>
            <h6 className="leading-5">{it}</h6>
          </li>
        ))}
      </ul>
      <div className="flex justify-center">
        <button
          className={`xl:w-[194px] md:w-[154px] w-full py-3 mx-auto font-semibold rounded-lg ${
            selected ? "bg-white text-[#2B574F]" : "bg-[#2B574F] text-white"
          }`}
          onClick={onClick}
        >
          Get Started
        </button>
      </div>
    </div>
  );
};
export const WhatCost = () => {
  const [selectItem, setSelectItem] = useState(3);
  return (
    <div className="mt-8 space-y-8 font-work_sans min-h-wrap xl:cost_xl_bg">
      <section className="space-y-8 xl:w-[1130px] md:w-[90vw] w-[90vw] mx-auto shadow-lg md:p-8 xl:rounded-xl rounded-lg bg-white p-6">
        <h6 className="text-xl font-bold text-center text-[#2B574F]">
          What it costs
        </h6>
        <p className="mx-auto text-[#2B574F] xl:w-4/5 text-center">
          After selecting a package you will continue onto the MyCollegeCosts.ai
          application. All packages include access to{" "}
          <b className="font-bold text-[#2B574F]">
            MyCollegeCosts.ai through August 31, 2025.
          </b>
        </p>
        <div className="flex flex-col xl:flex-row xl:justify-between">
          <SubType
            cost={25}
            title="Silver"
            header="Silver"
            selected={selectItem === 1}
            includes={[
              "List up to up to 1 - 5 schools you are considering",
              "Direct cost and aid you will need to attend for each school.",
              "The amount of money you will need to pay to attend each school.",
            ]}
            onClick={() => {
              setSelectItem(1);
            }}
          />
          <SubType
            cost={50}
            title="Gold"
            header="Gold"
            selected={selectItem === 2}
            includes={[
              "List up to up to 6 - 10 schools you are considering",
              "Direct cost and aid you will need to attend for each school.",
              "The amount of money you will need to pay to attend each school.",
            ]}
            onClick={() => {
              setSelectItem(2);
            }}
          />
          <SubType
            cost={75}
            title="Platinum"
            header="Platinum"
            selected={selectItem === 3}
            includes={[
              "List up to up to 11 -20 schools you are considering",
              "Direct cost and aid you will need to attend for each school.",
              "The amount of money you will need to pay to attend each school.",
            ]}
            onClick={() => {
              setSelectItem(3);
            }}
          />
          <SubType
            cost={30}
            title="Transfer Student"
            header="Transfer Student"
            selected={selectItem === 4}
            includes={[
              "List up to 5 schools not including the school at which you are currently enrolled.",
              "Direct cost and aid you will need to attend for each school.",
              "The amount of money you will need to pay to attend each school.",
            ]}
            onClick={() => {
              setSelectItem(4);
            }}
          />
        </div>
      </section>
    </div>
  );
};
