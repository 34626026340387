"use client";
import { useForm } from "react-hook-form";
import { Spinner } from "flowbite-react";
import { useState } from "react";
import up_arrow from "../assets/images/up_arrow.svg";
import down_arrow from "../assets/images/down_arrow.svg";
export const backend_baseUrl = process.env.REACT_APP_BACKEND_URL;
type QItemProp = {
  title: string;
  isExpanded: boolean;
  description: string;
  onClick: () => void;
};
export const QItem = ({
  title,
  isExpanded,
  description,
  onClick,
}: QItemProp) => {
  return (
    <div className="w-full p-4 border border-t-2 border-[#3FC6AD] border-b-0 border-x-0 bg-white space-y-4">
      <div className="flex justify-between cursor-pointer" onClick={onClick}>
        <h6
          className={`${
            isExpanded ? "font-bold" : ""
          } text-[#2B8B7A] text-lg flex-1`}
        >
          {title}
        </h6>
        <div className="shrink-0">
          <img src={isExpanded ? up_arrow : down_arrow} alt="" />
        </div>
      </div>
      {isExpanded ? (
        <p
          className="text-[#646464] font-light xl:text-lg"
          dangerouslySetInnerHTML={{ __html: description }}
        />
      ) : null}
    </div>
  );
};
export const FAQ = () => {
  const [selectedItem, setSelectedItem] = useState(0);
  return (
    <div className="flex flex-col items-center font-opensans min-h-wrap ">
      <div className="p-8 my-auto xl:faq_bg_decoration min-h-[600px] xl:w-[1080px] w-full mx-auto">
        <section className="xl:p-20 space-y-8 xl:w-[780px]">
          <div className="flex justify-between">
            <h5 className="xl:text-[40px] text-[#2B574F] font-semibold">FAQ</h5>
            <div className="flex items-center">
              <div className="flex space-x-4">
                <h6 className="text-[#2B574F] font-semibold">Expand all</h6>
                <div className="bg-[#646464] opacity-30 w-[2px]" />
                <h6 className="text-[#2B574F] font-semibold">Collapse all</h6>
              </div>
            </div>
          </div>
          <div className="bg-[#F3F5F6] rounded shadow-lg space-y-5 p-8">
            <QItem
              title="Question"
              description="Nibh quisque suscipit fermentum netus nulla cras porttitor euismod nulla. Orci, dictumst nec aliquet id ullamcorper venenatis. Fermentum sulla craspor ttitore  ismod nulla."
              isExpanded={selectedItem === 1}
              onClick={() => {
                if (selectedItem === 1) setSelectedItem(0);
                else setSelectedItem(1);
              }}
            />
            <QItem
              title="Question"
              description="Nibh quisque suscipit fermentum netus nulla cras porttitor euismod nulla. Orci, dictumst nec aliquet id ullamcorper venenatis. Fermentum sulla craspor ttitore  ismod nulla."
              isExpanded={selectedItem === 2}
              onClick={() => {
                if (selectedItem === 2) setSelectedItem(0);
                else setSelectedItem(2);
              }}
            />
            <QItem
              title="Question"
              description="Nibh quisque suscipit fermentum netus nulla cras porttitor euismod nulla. Orci, dictumst nec aliquet id ullamcorper venenatis. Fermentum sulla craspor ttitore  ismod nulla."
              isExpanded={selectedItem === 3}
              onClick={() => {
                if (selectedItem === 3) setSelectedItem(0);
                else setSelectedItem(3);
              }}
            />
            <QItem
              title="Question"
              description="Nibh quisque suscipit fermentum netus nulla cras porttitor euismod nulla. Orci, dictumst nec aliquet id ullamcorper venenatis. Fermentum sulla craspor ttitore  ismod nulla."
              isExpanded={selectedItem === 4}
              onClick={() => {
                if (selectedItem === 4) setSelectedItem(0);
                else setSelectedItem(4);
              }}
            />
            <QItem
              title="Question"
              description="Nibh quisque suscipit fermentum netus nulla cras porttitor euismod nulla. Orci, dictumst nec aliquet id ullamcorper venenatis. Fermentum sulla craspor ttitore  ismod nulla."
              isExpanded={selectedItem === 5}
              onClick={() => {
                if (selectedItem === 5) setSelectedItem(0);
                else setSelectedItem(5);
              }}
            />
            <QItem
              title="Question"
              description="Nibh quisque suscipit fermentum netus nulla cras porttitor euismod nulla. Orci, dictumst nec aliquet id ullamcorper venenatis. Fermentum sulla craspor ttitore  ismod nulla."
              isExpanded={selectedItem === 6}
              onClick={() => {
                if (selectedItem === 6) setSelectedItem(0);
                else setSelectedItem(6);
              }}
            />
            <QItem
              title="Question"
              description="Nibh quisque suscipit fermentum netus nulla cras porttitor euismod nulla. Orci, dictumst nec aliquet id ullamcorper venenatis. Fermentum sulla craspor ttitore  ismod nulla."
              isExpanded={selectedItem === 7}
              onClick={() => {
                if (selectedItem === 7) setSelectedItem(0);
                else setSelectedItem(7);
              }}
            />
          </div>
        </section>
      </div>
    </div>
  );
};
