import { Header, ILinkProps } from './Header';
import { Footer } from './Footer';
import { Link, Outlet, useLocation } from 'react-router-dom';
import { useEffect, useMemo, useState } from "react";
export const LinkFooterItem = ({ title, link, selected }: ILinkProps) => {
  return (
    <div className="flex items-center justify-center">
      <Link
        className={`max-xl:p-2 ${
          selected === link ? 'font-bold text-[#3FC6AD] underline underline-offset-[6px] decoration-4 ' : 'text-[#2B2B2B]'
        } text-lg md:text-base`}
        to={link}
      >
        {title}
      </Link>
    </div>
  );
};
export const Layout = () => {
  const [visible, setVisible] = useState(false);
  const toggleVisible = () => {
    const scrolled = document.documentElement.scrollTop;
    if (scrolled > 300) {
      setVisible(true);
    } else if (scrolled <= 300) {
      setVisible(false);
    }
  };

  const scrollToTop = () => {
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  };
  // console.log(location.pathname);
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  window.addEventListener("scroll", toggleVisible);

  return (
    <main>
      <Header />
      <Outlet />
      <Footer />
    </main>
  );
};
